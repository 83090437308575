@import '../../../styles/variaveis.scss';

.buttonYellow {
    background-color: #F2B705 !important;
    color: #153B7F;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: .5rem;
    padding: .6rem 1.5rem;
    font-family: 'PlusJakartaSans-Light';
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all .5s;

    &:hover {
        background-color: #F2B705 !important;
        transition: all .5s;
        transform: scale(1.1);
        cursor: pointer;
    }

    &:disabled {
        background-color: #F2B705 !important;
        color: #153B7F;
        cursor: not-allowed;
        opacity: 0.65;
    }
    &:focus {
        background-color: #F2B705 !important;
        color: #153B7F;
        cursor: not-allowed;
    }
}
