@import '../../../styles/variaveis.scss'; 


.modal {
  font-family: 'PlusJakartaSans-Medium';
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.modal.hide {
  opacity: 0;
}