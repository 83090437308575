@import '../../../styles/variaveis.scss';

.contentSection{
    margin-top: 5% !important;
    font-family: 'PlusJakartaSans-Medium';
}

.sideBarOpen{
    margin-left: calc(100% - 80%);
}

.sideBarClose{
    margin-left: calc(100% - 90%);
}

.close{
    width: calc(100% + 15%);
}  
.transitionWrapper {
  position: relative;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}