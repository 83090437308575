@import '../../../../../styles/variaveis.scss';

.navbarSection {
  width: 20%;
  transition: width 0.3s ease;
}

.container {
  display: flex;
  flex-direction: row;
}
.titleSection{
  margin-top: 5% !important;
}
.titleSection, .subTitleSection {
  margin-left: 20% !important;
}

.logoSection{
  margin-top: 12%;
  margin-bottom: -14%;
}
.contentSection {
  flex: 1;
  margin-top: 3% !important;
  margin-left: 23% !important;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;

  @media(max-width: 480px) {
    margin-left: 10% !important;
  }
  @media(max-width: 764px) {
    margin-left: 15% !important;
  }
}

.sideBarOpen {
  margin-left: 20%;
}

.sideBarClose {
  margin-left: 0;
}

.cardContainer {
  width: 300px; /* Set fixed width */
  height: 350px; /* Set fixed height */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; /* Ensure content does not overflow */
}

.cardHeader {
  background: $cardGradient !important; /* Cor $yellowSecondary */
  color: #fff; /* Cor do texto no header */
  font-weight: bold;
  text-align: center;
  font-family: 'PlusJakartaSans-Medium';
}

.cardBody {
  display: flex;
  border: 4px solid $yellowSecondary;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Ensure no overflow of content */
  padding: 15px; /* Adjust padding as needed */
}

.bankLogoSmall {
  margin-top: 10% !important;
  margin-left: 10% !important;
  max-width: 150px; /* Set a maximum width for bank logos */
  max-height: 35px; /* Set a maximum height for bank logos */
  width: auto;
  height: auto;
  margin-bottom: 10px; /* Space between the bank logo and other content */
}

.convenioLogo {
  max-width: 150px;
  margin-top: 10% !important;
  width: auto;
  height: auto;
  margin-bottom: 10px; /* Space between the covenant logo and other content */
}

.cardFooter {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.netAmount {
  font-size: 30px;
  font-weight: bold;
  color: $brownPrimary;
  margin-bottom: 20px;
}

.textAmount{
  color: $brownSecondary;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  font-family: 'PlusJakartaSans-Medium';
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.logoContainer{
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.buttonFirst{
  background: $yellowGradient;
  padding: .9rem;
  border: none;
  color: $brownPrimary;
  font-family: 'PlusJakartaSans-Bold';
  font-size: 16px;
  border-radius: .5rem;
  box-shadow: $boxShadow;
  transition: .3s;

  &:hover{
    transform: scale(1.1);
    transition: .3s;
    color: $brownPrimary;
  }
}

.buttonSecondary{
  background: $cardGradient;
  padding: .9rem 2rem;
  border: none;
  color: $brownPrimary;
  font-family: 'PlusJakartaSans-Bold';
  border-radius: .5rem;
  box-shadow: $boxShadow;
  font-size: 16px;
  
  &:hover{
    transform: scale(1.1);
    transition: .3s;
    color: $brownPrimary;
  }
}

.leroTriste{
  width: 170px;
  margin-top: -10px;
}

.leroTristeMessage{
  font-family: 'PlusJakartaSans-Bold';
  color: $brownPrimary;
  font-size: 1.3rem;
}

.badgeMargin{
  background-color: $brownPrimary !important;
  font-size: 0.8rem !important;
  font-family: 'PlusJakartaSans-Bold' !important;
  padding: 12px;
}

@media (max-width: 1450px) {
  .contentSection{
      margin-left: 6% !important;
  }
  .titlesSection{
      margin-left: -15%;
  }
}
.noOpportunityContainer{
  margin-top: 12%;
}
.noOpportunityImage{
  width: 170px;
}

.oportunityBage{
  font-size: 1rem;
}