@import '../../styles/variaveis.scss';
.walletContainer {
  max-width: 1400px;
  margin: 0 auto;
  margin-left: 20%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.table {
  margin-top: 15px;
}

.summary {
  margin-top: 20px;
  text-align: center;
  font-size: 1.1rem;
  color: #333;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
