@import '../../../../styles/variaveis.scss';

.resumeClientModal {
  font-family: 'PlusJakartaSans-Bold';
  // Aumentando a largura do modal para que ocupe mais espaço na tela grande
  .modal-dialog {
    max-width: 90%; // Altere o valor conforme necessário para ajustar a largura
  }

  // Tabelas dentro do modal
  table {
    width: 100%; // Garante que a tabela ocupe toda a largura do modal
    font-size: 1rem; // Tamanho da fonte da tabela
    
    @media (max-width: 768px) {
      font-size: 0.875rem; // Ajusta o tamanho da fonte da tabela em telas menores
    }
  }

  // Para contratos de empréstimo, adicionar rolagem vertical
  .contractsTableWrapper {
    max-height: 300px; // Defina um valor fixo para a altura da tabela
    overflow-y: auto; // Adiciona rolagem vertical
    display: block;  // Garante que a tabela tenha o comportamento de rolagem
  }

  // Estilizando o cabeçalho de forma mais profissional
  .modal-header {
    background-color: $orangePrimary; // Cor de fundo do cabeçalho
    color: $whitePrimary; // Cor do texto
    font-family: 'PlusJakartaSans-Bold'; // Fonte do título do modal
    border-bottom: 2px solid $orangeSecondary; // Borda de separação do conteúdo
  }

  .modal-title {
    font-size: 1.25rem; // Tamanho do título
  }

  .modal-body {
    padding: 1.5rem; // Ajusta o padding do corpo do modal
  }

  .headerClientInfo p {
    margin-bottom: 0.5rem; // Ajusta o espaçamento entre as informações
    font-family: 'PlusJakartaSans-Medium'; // Fonte das informações do cliente
  }

  .headerClientInfo {
    display: flex;
    flex-wrap: wrap; // Permite a quebra de linha
    gap: 1rem; // Espaçamento entre os itens
  
    .infoItem {
      background-color: $beigeSecondary; // Cor de fundo do quadradinho
      border-radius: 8px; // Bordas arredondadas
      padding: 1rem; // Espaçamento interno
      flex: 1 1 calc(33.333% - 1rem); // Garante 3 itens por linha, com espaçamento
      min-width: 250px; // Garante que cada item tenha uma largura mínima
      box-shadow: $boxShadow; // Adiciona sombra para o efeito de profundidade
      font-family: 'PlusJakartaSans-Medium'; // Fonte para o texto
      color: $fontColor; // Cor do texto
      display: flex; // Alinha título e valor
      justify-content: space-between; // Distribui o espaço entre título e valor
  
      // Faz com que os itens se comportem de forma responsiva
      @media (max-width: 768px) {
        flex: 1 1 100%; // Em telas menores, os itens ocupam toda a linha
      }
  
      strong {
        color: $orangeSecondary; // Cor para o texto em negrito
        margin-right: 0.5rem; // Espaçamento entre o título e o valor
      }
  
      .infoValue {
        text-align: right; // Alinha o valor à direita
      }
    }
  }
  

  .text-center {
    font-family: 'PlusJakartaSans-Light'; // Fonte para o texto do alerta
  }

  .btn-outline-danger {
    color: $orangeDanger;
    border-color: $orangeDanger;
  }
}
