
.analiseContainer {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    display: flex;
    flex-direction: column;
  }
  
  .fadeIn {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .backButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 10px;
    color: #333;
  }
  
  .title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .chartContainer {
    flex: 0 0 250px; // Altura fixa do gráfico
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tabButton {
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
  
    &.active {
      background-color: #007bff;
      color: #fff;
      border-color: #007bff;
    }
  }
  
  .tableContainer {
    flex: 1; // O restante do espaço disponível
    overflow: hidden; // Evita scroll interno
  }
  
  .dataTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
  
    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f5f5f5;
      font-weight: bold;
    }
  
    tbody {
      max-height: 200px; // Limita a altura da tabela
      overflow-y: auto; // Caso precise de scroll interno (opcional)
    }
  }