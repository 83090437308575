@import '../../../styles/variaveis.scss';

.modal {
    font-family: 'PlusJakartaSans-Medium';
    .modalContent {
      border-radius: 10px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      background-color: #ffffff; // Cor de fundo branca
    }
  
    .modalHeader {
      background-color: #007bff; // Cor do cabeçalho
      color: white; // Texto branco
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
  
      .modal-title {
        font-weight: bold;
        font-size: 1.5rem; // Tamanho da fonte do título
      }
    }
  
    .modalBody {
      padding: 2rem; // Espaçamento interno do corpo do modal
  
      h5 {
        font-weight: bold;
        margin-bottom: 1rem; // Espaçamento abaixo do título
        color: #333; // Cor do título
      }
  
      .simulationDetails {
        background-color: #f8f9fa; // Fundo cinza claro para detalhes da simulação
        padding: 1rem;
        border-radius: 5px;
        margin-top: 1rem;
  
        p {
          margin: 0.5rem 0; // Margem entre os parágrafos
          color: #555; // Cor do texto dos detalhes
          strong {
            color: $orangePrimary; // Cor dos textos em negrito
          }
        }
      }
  
      .bankDetails {
        margin-top: 2rem;
  
        .form-group {
          margin-bottom: 1.5rem; // Espaçamento entre os campos de formulário
        }
      }
    }
  
    .modalFooter {
      display: flex;
      justify-content: flex-end; // Alinha o botão à direita
  
      ButtonPrimary {
        background-color: #28a745; // Cor verde para o botão
        border-color: #28a745;
  
        &:hover {
          background-color: #218838; // Cor ao passar o mouse
          border-color: #1e7e34;
        }
        
        &:disabled {
          background-color: #ccc; // Cor quando desabilitado
          border-color: #ccc;
          cursor: not-allowed; // Cursor quando desabilitado
        }
      }
    }
  }
  
  // Estilos para o select do banco e simulação
  .react-select__control {
    border-radius: 5px;
    border-color: #ced4da; // Cor da borda padrão
  
    &:hover {
      border-color: #80bdff; // Cor da borda ao passar o mouse
    }
  
    &--is-focused {
      border-color: #007bff; // Cor da borda quando focado
      box-shadow: 0 0 0 0.2rem rgba(38,143,255,.25); // Sombra ao focar
    }
  }
  
  .react-select__menu {
    border-radius: 5px;
  }
  
  // Adicionando estilos para a barra de pesquisa no select (se necessário)
  .react-select__input input {
     padding-left: .5rem; // Espaçamento à esquerda do input
  }
  