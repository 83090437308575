@import '../../../styles/variaveis.scss';

.analiseContainer {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 600px; // Altura mínima, mas permite expansão
  height: auto; // Remove a altura fixa para se ajustar ao conteúdo
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeTransition {
  animation: fadeTransition 0.3s ease-in-out;
}

@keyframes fadeTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.backButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #333;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin: 0;
  flex-grow: 1;
}

.selectContainer {
  width: 250px;
}

.reactSelect {
  .react-select__control {
    height: 30px;
    min-height: 30px;
    font-size: 0.9rem;
  }
  .react-select__value-container {
    padding: 0 8px;
  }
  .react-select__indicators {
    height: 30px;
  }
}

.cardsContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #e9ecef;
  }

  &.selected {
    border-top: 4px solid $orangePrimary;
    background-color: #fff3e6;
  }
}

.cardIcon {
  font-size: 1.5rem;
  margin-right: 10px;
  color: $orangePrimary;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cardTitle {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.cardValue {
  font-size: 1rem;
  font-weight: bold;
  color: $orangePrimary;
}

.chartContainer {
  flex: 0 0 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.chartHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.timeFrameSelect {
  width: 150px;
  .react-select__control {
    height: 30px;
    min-height: 30px;
    font-size: 0.9rem;
  }
  .react-select__value-container {
    padding: 0 8px;
  }
  .react-select__indicators {
    height: 30px;
  }
}

.tableContainer {
  flex: 1;
  position: relative;
  margin-bottom: 20px;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
}
.filterRow {
  margin-bottom: 20px;
  align-items: center;
}

.floatingLabel {
  position: relative;
  width: 100%;
}

.floatingInput {
  width: 100%;
  padding: 10px 10px 10px 10px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: $orangePrimary;
  }

  &:focus + .floatingLabelText,
  &:not(:placeholder-shown) + .floatingLabelText {
    top: -8px;
    left: 10px;
    font-size: 0.75rem;
    color: $orangePrimary;
    background-color: #fff;
    padding: 0 5px;
  }
}

.floatingLabelText {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 0.9rem;
  color: #888;
  pointer-events: none;
  transition: all 0.2s ease;
}