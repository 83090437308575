@import '../../../styles/variaveis.scss';

.progressWrapper {
  position: relative;
  width: 100%;
}

.customProgressBar {
  height: 25px;
}

.customLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: $orangePrimary !important;
  z-index: 2;
}
