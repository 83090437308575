@import '../../../styles/variaveis.scss';

.dashboardCard {
  
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: #fff;

  &.clickable:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .redirectIcon {
    color: #007bff;
    transition: transform 0.2s ease;
  }

  &.clickable:hover .redirectIcon {
    transform: translateX(5px);
  }
}