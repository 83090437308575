@import '../../styles/variaveis.scss';

.container {
    max-width: 1300px;
    margin: 0 auto;
    font-family: 'PlusJakartaSans-Medium';
}

.step {
    transition: opacity 0.3s ease-in-out;
}

.fadeEnter {
    opacity: 0;
}

.fadeEnterActive {
    opacity: 1;
}

.fadeExit {
    opacity: 1;
}

.fadeExitActive {
    opacity: 0;
}

.tableContainer {
    max-height: 450px;
    overflow-y: auto;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;

    th,
    td {
        padding: 0.75rem;
        font-size: 0.9rem !important;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
    }

    tbody + tbody {
        border-top: 2px solid #dee2e6;
    }
}