@import '../../../styles/variaveis.scss';


.logoSection{
    margin-top: 12%;
    margin-bottom: -14%;
}
.contentSection{
    margin-left: 20%;
    font-family: 'PlusJakartaSans-Medium' !important;
}