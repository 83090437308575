@import '../../../styles/variaveis.scss';

.ButtonTyping {
    background-color: #168044;
    color: $whitePrimary;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: .5rem;
    padding: .6rem 1.5rem;
    font-family: 'PlusJakartaSans-Light';
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all .5s;

    &:hover {
        background-color: #168044;
        transition: all .5s;
        transform: scale(1.1);
        cursor: pointer;
    }

    &:disabled {
        background-color: #16807A;
        color: $whitePrimary; 
        cursor: not-allowed;
        opacity: 0.65;
    }
    &:focus {
        background-color: #16807A !important;
        color: $whitePrimary !important; 
        cursor: not-allowed;
    }
}
