.termometroContainer {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 0 auto;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
    }
  
    .title {
      font-size: 1.25rem;
      font-weight: bold;
      color: #333;
      margin: 0;
      text-align: start;
    }
  
    .selectContainer {
      width: 250px;
    }
  
    .reactSelect {
      .react-select__control {
        height: 30px;
        min-height: 30px;
        font-size: 0.9rem;
      }
  
      .react-select__value-container {
        padding: 0 8px;
      }
  
      .react-select__indicators {
        height: 30px;
      }
    }
  
    .metrics {
      font-size: 0.9rem;
      color: #555;
      margin-bottom: 20px;
  
      p {
        margin: 5px 0;
      }
  
      strong {
        color: #333;
      }
    }
  
    .progress {
      height: 25px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 1rem;
  
      .progress-bar {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    .legend {
      margin-top: 3%;
      font-size: 0.85rem;
      display: flex;
      justify-content: space-between;
  
      span {
        padding: 2px 8px;
        border-radius: 3px;
      }
  
      .success {
        background-color: #d4edda;
        color: #155724;
      }
  
      .warning {
        background-color: #fff3cd;
        color: #856404;
      }
  
      .danger {
        background-color: #f8d7da;
        color: #721c24;
      }
    }
  }