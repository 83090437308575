@import '../../../../styles/variaveis.scss';

.lotDetailsModal {

  table {
    width: 100%; // Garante que a tabela ocupe toda a largura do modal
    @media (max-width: 768px) {
      font-size: 0.875rem; // Ajusta o tamanho da fonte da tabela em telas menores
    }
  }
}
