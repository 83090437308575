/* ClientData.module.scss */
@import '../../../../styles/variaveis.scss';

.container {
  display: flex;
  flex-direction: row;
}

.titleSection {
  margin-top: 5% !important;
}

.titleSection,
.subTitleSection {
  margin-left: 20% !important;
}

.navbarSection {
  width: 20%;
  transition: width 0.3s ease;
}

.logoSection {
  margin-top: 12%;
  margin-bottom: -14%;
}

.contentSection {
  flex: 1;
  margin-top: 2% !important;
  font-family: 'PlusJakartaSans-Medium';
  margin-left: 23% !important;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;

  @media (max-width: 480px) {
    margin-left: 10% !important;
  }

  @media (max-width: 764px) {
    margin-left: 20% !important;
  }
}

.sideBarOpen {
  margin-left: 20% !important;
}

.sideBarClose {
  margin-left: 15% !important;
}

.balanceConatiner {
  margin-right: 2rem;
}

.floatingLabel {
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    padding: 0.95rem;
    font-size: 1rem;
    border: 1px solid $grayPrimary;
    border-radius: .5rem;
    color: $orangePrimary !important;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
      border-color: $orangePrimary;
      box-shadow: 0 0 0 0.2rem rgba($orangePrimary, 0.25);
      outline: none;
    }

    &::placeholder {
      color: transparent;
      /* Oculta o placeholder quando o label está flutuando */
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    font-size: 1rem;
    color: $orangePrimary;
    pointer-events: none;
    transition: 0.3s ease all;
    transform: translateY(-50%);
    background: $whitePrimary;
    /* Garante que o fundo não fique transparente quando flutua */
    padding: 0 0.25rem;
  }

  input:focus+label,
  input:not(:placeholder-shown)+label {
    top: -0.5rem;
    left: 0.75rem;
    font-size: 0.75rem;
    color: $orangePrimary;
  }

  @media (max-width: 400px) {
    max-width: 20rem;
  }

  @media (max-width: 764px) {
    max-width: 170% !important;
  }
}

@media (max-width: 1450px) {
  .contentSection {
    margin-left: 5% !important;
  }

  .titlesSection {
    margin-left: -15%;
  }
}

/* Styles for Benefit Cards */
.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  margin-top: 20px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
}

.benefitCard {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, border-color 0.2s;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  max-width: 550px !important;

  &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
      border-color: #153B7F;
  }
}

.selected {
    border: 2px solid #153B7F; // Borda azul
    background-color: #f0f8ff; // Fundo mais claro
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
      border-color: #153B7F;
    }
  
    &::after {
      content: '✔';
      position: absolute;
      top: 10px;
      right: 10px;
      color: $orangePrimary;
      font-size: 1.5rem;
      animation: fadeIn 0.5s ease-in-out;
    }
  }

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background-color: #f8f9fa;

  &:hover {
    transform: none;
    box-shadow: none;
    border-color: #e0e0e0;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}


/* Styles for Footer */
.footerContainer {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #e0e0e0;

  h5 {
    color: #153B7F;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
